<template>
  <div>
    <v-card color="#0F4A6D" class="hidden-md-and-up" tile elevation="0">
      <v-card-text class="white--text text-uppercase text-center">Detalles de la reserva</v-card-text>
    </v-card>
    <v-container>
      <div v-for="(item,index) in resume" :key="index">
        <p class="font-weight-light text-uppercase mb-0">
          {{ item.label }}
        </p>
        <p class="font-weight-black">
          {{ item.value }}
        </p>
      </div>
      <div>
        <v-btn depressed x-large block tile class="orange white--text mt-6" @click="goToPayment">
          <v-icon small>mdi-star-three-points-outline</v-icon> Confirmar reserva
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Details",
  methods:{
    goToPayment(){
      return this.$router.push({name:'MobilePayment'})
    },
    getDate(scheduleDate){
      let date = this.$moment(scheduleDate).locale('es')
      return {
        date: date.format('dddd, D [de] MMMM [de] YYYY'),
        hour: date.format('HH[:]mm')
      }
    }
  },
  mounted() {
    this.$store.commit('setLoading', false)

  },
  computed:{
    resume(){
      const reservation = this.$store.state.reservation
      const amphiEvents = this.$store.state.amphiEvents
      const amphiEvent = amphiEvents.find(elem => elem.id === reservation.event_schedule_id.amphi_event_id)
      const amphiDate = this.getDate(reservation.event_schedule_id.schedule)
      const price = reservation.amphi_table.capacity * amphiEvent.price
      return [
        {
          label:'Evento',
          value: amphiEvent.name
        }, {
          label:'Fecha',
          value: amphiDate.date
        }, {
          label:'Horario',
          value: amphiDate.hour +' HS'
        }, {
          label:'Mesa',
          value: reservation.amphi_table.name
        }, {
          label:'Asientos',
          value: reservation.amphi_table.capacity
        },{
          label:'Total',
          value: price+' €'
        },
      ]
    },
    reserveId(){
      return this.$route.params.id || this.$route.query.id
    },
    user(){
      return this.$route.query.buyer || 'store.user'
    }
  }
}
</script>

<style scoped>

</style>